<template>
	<b-container fluid>
		<b-row>
			<b-col md="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Historial Clínico</h4>
					</template>
					<template v-slot:headerAction>
						<b-button
							variant="primary"
							@click="showModalAdd(data)"
						>
							Agregar
						</b-button>
					</template>
					<template v-slot:body>
						<DataTable
							v-if="history"
							:data="history"
							:columns="columns"
							:fields-search="[
								'diagnoses',
								'weight',
								'height',
								'systolic_pressure',
								'diastolic_pressure',
							]"
						>
							<template #rows="{ row, rowIndex }">
								<tr>
									<th scope="row">{{ rowIndex + 1 }}</th>
									<td>
										{{ row.user.first_last_name }} {{ row.user.second_last_name }},
										{{ row.user.name }}
									</td>
									<td>
										<div v-if="row.diagnoses">
											<span v-for="(diagnose, index) in row.diagnoses" :key="index">
												{{ diagnose.description }}
											</span>
										</div>
									</td>
									<td>
										{{ row.weight ? parseInt(row.weight) : "-" }}
									</td>
									<td>
										{{ row.height ? parseInt(row.height) : "-" }}
									</td>
									<td>
										{{ row.systolic_pressure ? parseInt(row.systolic_pressure) : "-" }}
									</td>
									<td>
										{{ row.diastolic_pressure ? parseInt(row.diastolic_pressure) : "-" }}
									</td>
									<td>
										{{ row.created_at }}
									</td>
									<td>
										<b-button-group>
											<b-button
												v-if="role != 'Paciente' && lastId === row.id"
												v-user-can="'leer pacientes'"
												variant="warning"
												size="sm"
												@click="showModalEdit(row)"
												v-b-tooltip.hover
												title="Editar"
											>
												<i class="ri-ball-pen-fill m-0"></i>
											</b-button>
											<b-button
												v-user-can="'leer pacientes'"
												variant="secondary"
												size="sm"
												@click="showModal(row)"
											>
												<i class="ri-eye-fill m-0"></i>
											</b-button>
											<b-button
												v-user-can="'leer pacientes'"
												variant="success"
												size="sm"
												@click="downloadPdf(row)"
											>
												<i class="ri-file-fill m-0"></i>
											</b-button>
										</b-button-group>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</iq-card>
			</b-col>
		</b-row>

		<b-modal id="modal-data" title="Historial Clínico" hide-footer>
			<ul>
				<li><b>Institucion del Sistema:</b> {{ data.institucion }}</li>
				<li><b>RUC:</b> {{ data.ruc }}</li>
				<li><b>CIU:</b> {{data.ciu}}</li>
				<li><b>Establecimiento de Salud:</b> {{ data.establecimiento }}</li>
				<li><b>Motivo de Consulta:</b> {{ data.reason_consultation }}</li>
				<li><b>Antecedentes Clinico:</b> {{ data.clinical_history }}</li>
				<li><b>Antecedentes Quirurgico:</b> {{ data.surgical_history }}</li>
				<li><b>Tabaco:</b> {{ data.tabaco }}</li>
				<li><b>Alcohol:</b> {{ data.alcohol }}</li>
				<li><b>Actividad Fisica:</b> {{ data.actividad }}</li>
				<li><b>Medicacion:</b> {{ data.medicacion }}</li>
				<li><b>Antecedentes Familiares:</b> {{ data.family_background }}</li>
				<li><b>Enfermedad Actual:</b> {{ data.current_illness }}</li>
				<li><b>Revision de Organos y Sistemas:</b> {{ data.organ_check }}</li>
				<li><b>Observaciones Adicionales:</b> {{ data.organ_description }}</li>
				<li><b>Piel:</b> {{ data.piel }}</li>
				<li><b>Ojos:</b> {{ data.ojos }}</li>
				<li><b>Oido:</b> {{ data.oido }}</li>
				<li><b>Faringe:</b> {{ data.faringe }}</li>
				<li><b>Nariz:</b> {{ data.nariz }}</li>
				<li><b>Cuello:</b> {{ data.cuello }}</li>
				<li><b>Torax:</b> {{ data.torax }}</li>
				<li><b>Abdomen:</b> {{ data.abdomen }}</li>
				<li><b>Columna:</b> {{ data.columna }}</li>
				<li><b>Pelvis:</b> {{ data.pelvis }}</li>
				<li><b>Extremidades:</b> {{ data.extremidades }}</li>
				<li><b>Neurologico:</b> {{ data.neurologico }}</li>
				<li><b>Observaciones:</b> {{ data.regions_observations }}</li>
				<li><b>Observaciones de Resultados:</b> {{ data.results_description }}</li>
				<li><b>Recomendaciones Y/O Tratamientos:</b> {{ data.treatment }}</li>
				<li><b>Temperatura:</b> {{ data.temperature }}</li>
				<li><b>Frecuencia Cardiaca:</b> {{ data.blood_pressure }}</li>
				<li><b>Saturacion de Oxigeno:</b> {{ data.oxigen }}</li>
				<li><b>Frecuencia Respiratoria:</b> {{ data.frequency }}</li>
				<li><b>Peso (kg):</b> {{ data.weight }}</li>
				<li><b>Estatura:</b> {{ data.height }}</li>
				<li><b>Presión Arterial Sistólica:</b> {{ data.systolic_pressure }}</li>
				<li><b>Presión Arterial Diastólica:</b> {{ data.diastolic_pressure }}</li>
			</ul>
		</b-modal>

		<b-modal
			id="modal-edit"
			title="Historial Clínico"
			hide-footer
			size="lg"
			no-close-on-backdrop
		>
			<ValidationObserver ref="form" v-slot="{ handleSubmit }">
				<b-form @submit.stop.prevent="handleSubmit(onSubmitExtraData)">
					<b-row>
						<b-form-group class="col-md-6" label="Institucion del Sistema:">
							<ValidationProvider name="Institucion del Sistema"  v-slot="{ errors }">
								<b-form-input
									v-model="data.institucion"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="RUC:">
							<ValidationProvider name="RUC"  v-slot="{ errors }">
								<b-form-input
									v-model="data.ruc"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="CIU:">
							<ValidationProvider name="CIU"  v-slot="{ errors }">
								<b-form-input
									v-model="data.ciu"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Establecimiento de Salud:">
							<ValidationProvider name="Establecimiento de Salud"  v-slot="{ errors }">
								<b-form-input
									v-model="data.establecimiento"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-12" label="Motivo de Consulta:">
							<ValidationProvider name="Motivo de Consulta:"  v-slot="{ errors }">
								<b-form-textarea
									v-model="data.reason_consultation"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<hr />
						</div>
						<div class="col-md-12">
							<p>ANTESCEDENTES PERSONALES</p>
						</div>
						<b-form-group class="col-md-6" label="Clinico:">
							<ValidationProvider name="Clinico:"  v-slot="{ errors }">
								<b-form-input
									v-model="data.clinical_history"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="QUIRURGICOS:">
							<ValidationProvider name="QUIRURGICOS:"  v-slot="{ errors }">
								<b-form-input
									v-model="data.surgical_history"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<hr />
						</div>
						<div class="col-md-12">
							<p>HABITOS Y ESTILO DE VIDA</p>
						</div>
						<b-form-group class="col-md-6" label="TABACO:">
							<ValidationProvider name="TABACO:"  v-slot="{ errors }">
								<b-form-input
									v-model="data.tabaco"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="ALCOHOL:">
							<ValidationProvider name="ALCOHOL:"  v-slot="{ errors }">
								<b-form-input
									v-model="data.alcohol"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="ACTVIDAD FISICA:">
							<ValidationProvider name="ACTIVIDAD FISICA:"  v-slot="{ errors }">
								<b-form-input
									v-model="data.actividad"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="MEDICACION:">
							<ValidationProvider name="MEDICACION:"  v-slot="{ errors }">
								<b-form-input
									v-model="data.medicacion"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<hr />
						</div>
						<b-form-group class="col-md-12" label="Antecedentes Familiares:">
							<ValidationProvider name="Antecedentes Familiares:"  v-slot="{ errors }">
								<b-form-textarea
									v-model="data.family_background"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-12" label="Enfermedad Actual:">
							<ValidationProvider name="Enfermedad Actual:"  v-slot="{ errors }">
								<b-form-textarea
									v-model="data.current_illness"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<!-- <b-form-group class="col-md-6" label="Diagnósticos:">
							<ValidationProvider
								name="Diagnósticos"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.diagnoses"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Tipo de Sangre:">
							<ValidationProvider
								name="Tipo de Sangre"
								
								v-slot="{ errors }"
							>
								<b-form-select
									v-model="data.blood_type"
									plain
									:options="['A+', 'O+', 'B+', 'AB+', 'A-', 'O-', 'B-', 'AB-']"
									id="blood_type"
								>
								</b-form-select>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group> -->
						<div class="col-md-12">
							<hr />
						</div>
						<b-form-group class="col-md-6" label="Revision de Organos y Sistemas:">
							<ValidationProvider name="Revision de Organos y Sistemas"  v-slot="{ errors }">
								<v-select
									:options="[
										'PIEL - ANEXOS',
										'ORGANOS DE LOS SENTIDOS',
										'RESPIRATORIO',
										'CARDIO- VASCULAR',
										'DIGESTIVO',
										'GENITO- URINARIO',
										'MUSCULO- ESQUELETICO',
										'ENDOCRINO',
										'HEMO- LINFATIVO',
										'NERVISOS',
									]"
									v-model="data.organ_check"
									:class="errors.length > 0 ? ' is-invalid' : ''"
									multiple
								/>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Observaciones Adicionales:">
							<ValidationProvider
								name="Observaciones"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.organ_description"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12">
							<hr />
						</div>
						<div class="col-md-12">
							<p>Examen Fisio Regional</p>
						</div>
						<b-form-group
							class="col-md-4"
							label-for="Piel"
							label="Piel:"
						>
							<b-form-select
								v-model="data.piel"
								plain
								:options='["Cicatrices", "Tatuajes", "Piel y Faneras", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Ojos"
							label="Ojos:"
						>
							<b-form-select
								v-model="data.ojos"
								plain
								:options='["Párpados", "Conjuntivas", "Pupilas", "Córnea", "Motilidad", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Oido"
							label="Oido:"
						>
							<b-form-select
								v-model="data.oido"
								plain
								:options='["C. auditivo externo", "Pabellón", "Tímpanos", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Faringe"
							label="Faringe:"
						>
							<b-form-select
								v-model="data.faringe"
								plain
								:options='["Labios", "Lengua", "Faringe", "Amígdalas", "Dentadura", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Nariz"
							label="Nariz:"
						>
							<b-form-select
								v-model="data.nariz"
								plain
								:options='["Tabique", "Cornetes", "Mucosas", "Senos paranasales", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Cuello"
							label="Cuello:"
						>
							<b-form-select
								v-model="data.cuello"
								plain
								:options='["Tiroides / masas", "Movilidad", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Torax"
							label="Torax:"
						>
							<b-form-select
								v-model="data.torax"
								plain
								:options='["Mamas", "Corazón", "Pulmones", "Parrilla Costal", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Abdomen"
							label="Abdomen:"
						>
							<b-form-select
								v-model="data.abdomen"
								plain
								:options='["Vísceras", "Pared abdominal", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Columna"
							label="Columna:"
						>
							<b-form-select
								v-model="data.columna"
								plain
								:options='["Flexibilidad", "Desviacion", "Dolor", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Pelvis"
							label="Pelvis:"
						>
							<b-form-select
								v-model="data.pelvis"
								plain
								:options='["Pelvis", "Genitales", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Extremidades"
							label="Extremidades:"
						>
							<b-form-select
								v-model="data.extremidades"
								plain
								:options='["Vascular", "Miembros superiores", "Miembros inferiores", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group
							class="col-md-4"
							label-for="Neurologico"
							label="Neurologico:"
						>
							<b-form-select
								v-model="data.neurologico"
								plain
								:options='["Fuerza", "Sensibilidad", "Marcha", "Reflejos", "Ninguno"]'
								id="fuerza"
							>
							</b-form-select>
						</b-form-group>
						<b-form-group class="col-md-12" label="Observaciones:">
							<ValidationProvider
								name="Observaciones Fisico"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.regions_observations"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<!-- <div class="col-md-12">
							<p>Antecedentes Personales</p>
						</div>
						<b-form-group class="col-md-4" label="Problemas de salud pasados y actuales:">
							<ValidationProvider
								name="Problemas de salud pasados y actuales"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.health_problems"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="Condiciones crónicas o enfermedades:">
							<ValidationProvider
								name="Condiciones crónicas o enfermedades"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.chronic_conditions_diseases"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="Lesiones previas o cirugías:">
							<ValidationProvider
								name="Lesiones previas o cirugías"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.previous_injuries_surgeries"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-4" label="Alergias conocidas:">
							<ValidationProvider
								name="Alergias conocidas"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.known_allergies"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group
							class="col-md-8"
							label="Medicamentos que el paciente esté tomando actualmente:"
						>
							<ValidationProvider
								name="Medicamentos que el paciente esté tomando actualmente"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.medications_currently_taking"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group> -->
						<div class="col-md-12">
							<hr />
						</div>
						<div class="col-md-12">
							<p>Resultado De Examenes (Imagen, Laboratorio y Otros)</p>
						</div>
						<table class="col-md-12 table" style="padding: 0px 15px">
							<thead class="text-center">
								<tr>
									<th>Nombre</th>
									<th>Examen</th>
									<th>Fecha</th>
									<th>Resultado</th>
									<th>-</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in results">
									<td>{{ item.name }}</td>
									<td>
										<div class="row">
											<div v-for="image in item.images" class="col-6">
												<img :src="image" alt="" class="mw-100" />
											</div>
										</div>
									</td>
									<td>{{ 
										item.date 
									}}</td>
									<td>{{ item.result }}</td>
									<td>
										<button class="btn btn-warning" @click="() => handleDeleteResult(item.result)">X</button>
									</td>
								</tr>
								<tr>
									<td>
										<b-form-input 
											v-model="result.name"
										></b-form-input>
									</td>
									<td style="max-width: 300px;">
										<b-form-group class="col-md-12" label="Imagenes:">
											<ValidationProvider
												name="Imagenes"
												v-slot="{ errors }"
											>
												<input
													type="file"
													multiple
													accept="image/*"
													@change="previewImages"
													:class="errors.length > 0 ? ' is-invalid' : 'mb-2'"
												/>
												<div class="invalid-feedback">
													<span>{{ errors[0] }}</span>
												</div>
											</ValidationProvider>
											<div class="row" v-if="images.length > 0">
												<div v-for="(image, index) in images " :key="index" class="col-12">
													<img :src="image" alt="" class="mw-100" />
												</div>
											</div>
										</b-form-group>
										<!-- <b-form-input 
											type="text"
											v-model="result.examen"
										></b-form-input> -->
										<!-- <input 
											type="file"
										></input> -->
									</td>
									<td>
										<b-form-input
											type="date"
											v-model="result.date"
										></b-form-input>
									</td>
									<td>
										<b-form-input 
											v-model="result.result"
										></b-form-input>
									</td>
									<td>
										<button @click="handleAddResult" class="btn btn-primary">Agregar</button>
									</td>
								</tr>
							</tbody>
						</table>
						<b-form-group class="col-md-12" label="Observaciones de Resultados:">
							<ValidationProvider
								name="Observaciones"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.results_description"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>

						<div class="col-md-12">
							<p>K. DIAGNÓSTICO</p>
						</div>
						<table class="col-md-12 table" style="padding: 0px 15px">
							<thead class="text-center">
								<tr>
									<th>Descripción</th>
									<th>CIE</th>
									<th>PRE/DEF</th>
									<th>-</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in diagnosses">
									<td>{{ item.description }}</td>
									<td>{{ item.cie }}</td>
									<td>{{ item.pre }}</td>
									<td>
										<button class="btn btn-warning" @click="() => handleDeleteDiagnosis(item.cie)">X</button>
									</td>
								</tr>
								<tr>
									<td>
										<b-form-textarea
											:value="diagnosis_description"
										></b-form-textarea>
										<!-- <input 
											type="file"
										></input> -->
									</td>
									<td>
										<b-form-input 
											list="my-list-id"
											@change="handleChangeCie"
										></b-form-input>
										<datalist id="my-list-id">
											<option v-for="code in codes" :key="code.code" :value="code.code">{{ code.description }}</option>
										</datalist>
									</td>
									<td>
										<b-form-group label-class="d-block">
											<b-form-radio
												inline
												v-model="diagnosis.pre"
												value="pre"
											>
												PRE
											</b-form-radio>
											<b-form-radio 
												inline 
												v-model="diagnosis.pre" 
												value="def"
											>
												DEF
											</b-form-radio>
										</b-form-group>
									</td>
									<td>
										<button @click="handleAddDiagnosis" class="btn btn-primary">Agregar</button>
									</td>
								</tr>
							</tbody>
						</table>
						<b-form-group class="col-md-12" label="Recomendaciones Y/O Tratamientos:">
							<ValidationProvider
								name="Recomendaciones"
								
								v-slot="{ errors }"
							>
								<b-form-textarea
									v-model="data.treatment"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-textarea>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<!-- <b-form-group
							class="col-md-6"
							label="Terapias o tratamientos de fisioterapia:"
						>
							<ValidationProvider
								name="Terapias o tratamientos de fisioterapia"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.therapies"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hábitos:">
							<ValidationProvider name="Hábitos"  v-slot="{ errors }">
								<v-select
									:options="[
										'ALCOHOL',
										'CIGARRILLOS',
										'EJERCICIOS',
										'BUENA ALIMENTACIÓN',
										'MALA ALIMENTACIÓN',
										'CAFEINA',
									]"
									v-model="data.habits"
									:class="errors.length > 0 ? ' is-invalid' : ''"
									multiple
								/>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group
							class="col-md-6"
							label="Información sobre estilo de vida y actividad fisica:"
						>
							<ValidationProvider
								name="Información sobre estilo de vida y actividad fisica"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.lifestyle"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hábitos alimentarios relevantes:">
							<ValidationProvider
								name="Hábitos alimentarios relevantes"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.relevant_habits"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Hábitos de sueño:">
							<ValidationProvider
								name="Hábitos de sueño"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.sleep_habits"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-6" label="Condición de salud mental:">
							<ValidationProvider
								name="Condición de salud mental"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.mental_health_condition"
									type="text"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group> -->
						<b-form-group class="col-md-3" label="TEMPERATURA:">
							<ValidationProvider name="TEMPERATURA"  v-slot="{ errors }">
								<b-form-input
									v-model="data.temperature"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="FRECUENCIA CARDIACA:">
							<ValidationProvider name="FRECUENCIA CARDIACA"  v-slot="{ errors }">
								<b-form-input
									v-model="data.heart_rate"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="SATURACIÓN DE OXÍGENO:">
							<ValidationProvider name="SATURACIÓN DE OXÍGENO"  v-slot="{ errors }">
								<b-form-input
									v-model="data.oxigen"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="FRECUENCIA RESPIRATORIA:">
							<ValidationProvider name="FRECUENCIA RESPIRATORIA"  v-slot="{ errors }">
								<b-form-input
									v-model="data.frequency"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Peso (kg):">
							<ValidationProvider name="Peso"  v-slot="{ errors }">
								<b-form-input
									v-model="data.weight"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Estatura (cm.):">
							<ValidationProvider name="Estatura"  v-slot="{ errors }">
								<b-form-input
									v-model="data.height"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Presión Arterial Sistólica:">
							<ValidationProvider
								name="Presión Arterial"
								
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.systolic_pressure"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group class="col-md-3" label="Presión Arterial Diastólica:">
							<ValidationProvider
								name="Presión Arterial"
								v-slot="{ errors }"
							>
								<b-form-input
									v-model="data.diastolic_pressure"
									type="number"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								></b-form-input>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<b-form-group
							class="col-md-12"
							label-for="Personal de Salud"
							label="Personal de Salud:"
						>
							<b-form-select
								v-model="data.doctor_id"
								plain
								:options='doctors'
								id="salud"
							>
							</b-form-select>
						</b-form-group>
					</b-row>
					<b-button type="submit" variant="primary" class="mr-2"> Guardar </b-button>
					<b-button type="reset" variant="none" class="iq-bg-danger"> Cancelar </b-button>
				</b-form>
			</ValidationObserver>
		</b-modal>
	</b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import Swal from "sweetalert2";
import listCodes from "../../../Utils/codes.json";

export default {
	components: {
		DataTable,
	},
	data() {
		return {
			role: null,
			data: {
				institucion: null,
				occupation: null,
				diagnoses: null,
				blood_type: null,
				family_records: null,
				health_problems: null,
				chronic_conditions_diseases: null,
				previous_injuries_surgeries: null,
				known_allergies: null,
				medications_currently_taking: null,
				therapies: null,
				habits: [],
				lifestyle: null,
				relevant_habits: null,
				sleep_habits: null,
				mental_health_condition: null,
				weight: null,
				height: null,
				systolic_pressure: null,
				diastolic_pressure: null,
			},
			type: "add",
			history: [],
			columns: [
				{
					name: "Paciente",
					field: "name",
					sort: "",
				},
				{
					name: "Diagnóstico",
					field: "diagnoses",
					sort: "",
				},
				{
					name: "Peso (kg)",
					field: "weight",
					sort: "",
				},
				{
					name: "Altura",
					field: "height",
					sort: "",
				},
				{
					name: "Presión Sistólica",
					field: "systolic_pressure",
					sort: "",
				},
				{
					name: "Presión Diastólica",
					field: "diastolic_pressure",
					sort: "",
				},
				{
					name: "Fecha",
					field: "created_at",
					sort: "",
				},
				{
					name: "Acciones",
					field: "actions",
					sort: "",
				},
			],
			lastId: null,
			type: "add",
			result: {},
			results: [],
			diagnosis: {},
			diagnosses: [],
			images: [],
			doctors: [],
			fecha: '',
			diagnosis_description: '',
			diagnosis_code: '',
			codes: []
		};
	},
	filters: {
		formatDate: function(value) {
			if(!value) return ''
			const [year, month, day] = value.split('-')
			return `${day}/${month}/${year}`
		}
	},
	mounted() {
		xray.index();
		this.getData();
		this.getDoctors();
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
		this.codes = listCodes;
	},
	methods: {
		handleChangeCie(event) {
			const description = this.codes.find((item) => item.code == event.toUpperCase() ? item : "");
			if(description == undefined) return
			this.diagnosis_description = description.description
			this.diagnosis_code = description.code
		},
		formatNumber(num) {
			return parseInt(num)
		},
		previewImages(event) {
			const files = event.target.files
			this.result.images = files
			if(files && files.length > 0) {
				this.images = [];
				Array.from(files).forEach(file => {
					const lector = new FileReader();
					lector.onload = (e) => {
						this.images.push(e.target.result)
					}
					lector.readAsDataURL(file)
				})
			}
		},
		handleAddResult(e) {
			e.preventDefault()
			const date = new Date(this.result.date);
			const newDate = this.result.date.split('-');
			this.result.date = `${newDate[2]}/${newDate[1]}/${newDate[0]}`
			this.results = [...this.results, this.result]
			this.images = []
			this.result = {}
		},
		handleDeleteResult(local_result) {
			this.results = this.results.filter(result => result.result != local_result)
		},
		handleAddDiagnosis(e) {
			e.preventDefault()
			const newDiagnosis = {
				...this.diagnosis,
				cie: this.diagnosis_code,
				description: this.diagnosis_description
			}
			this.diagnosses = [...this.diagnosses, newDiagnosis]
			this.diagnosis = {}
		},
		handleDeleteDiagnosis(cie) {
			this.diagnosses = this.diagnosses.filter(result => result.cie != cie)
		},
		async getDoctors() {
			try {
				const { data } = await api.get(
					'/doctors'
				)
				this.doctors = [...data.data.map(item => {
					return {
						value: item.id,
						text: item.name
					}
				})];
			} catch(e) {
				console.log(e)
			}
		},
		async getData() {
			try {
				const { data } = await api.get(
					`/medical-history?patient_id=${this.$route.params.id}`
				);
				this.history = data.data;
				this.lastId = this.history ? this.history[0].id : null;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		showModal(data) {
			this.data = data;
			this.$bvModal.show("modal-data");
		},
		showModalAdd(data) {
			this.data = {};
			this.type = "add";
			this.$bvModal.show("modal-edit");
			this.data = data;
		},
		showModalEdit(data) {
			data = {
				...data,
				diastolic_pressure: parseInt(data.diastolic_pressure),
				systolic_pressure: parseInt(data.systolic_pressure),
				height: parseInt(data.height),
				weight: parseInt(data.weight),
				heart_rate: parseInt(data.heart_rate),
				temperature: parseInt(data.temperature),
				frequency: parseInt(data.frequency),
				oxigen: parseInt(data.oxigen)
			}
			this.data = {
				...data
			};
			this.type = "edit";
			this.$bvModal.show("modal-edit");
		},
		destroy(id) {
			Swal.fire({
				icon: "warning",
				title: "Seguro de eliminar",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					api
						.delete(`/medical-history/${id}`)
						.then((res) => {
							if (res.status === 200) {
								Toast.fire({
									icon: "success",
									title: "Eliminado correctamente",
								});
								this.getData();
							}
						})
						.catch((error) => {
							Toast.fire({
								icon: "error",
								title: error.message,
							});
						});
				}
			});
		},
		async onSubmitExtraData() {
			const url = `/patients/${this.$route.params.id}/extra`;
			const formData = new FormData();

			this.results.forEach((item, index) => {
				formData.append(`results[${index}][name]`, item.name);
				formData.append(`results[${index}][date]`, item.date);
				formData.append(`results[${index}][result]`, item.result);

				for(let i = 0; i < item.images.length; i++) {
					formData.append(`results[${index}][images][]`, item.images[i])
				}
			})

			let response;

			try {
				this.data = {
					...this.data,
					exam_results: [],
					diagnoses: this.diagnosses,
				}
				if (this.type === "add") {
					response = await api.post(url, this.data);
				} else {
					response = await api.put(url, this.data);
				}

				if(response) {
					await api.post(`/patients/${this.$route.params.id}/history/images`, formData);
				}
				await this.getData();
				this.$bvModal.hide("modal-edit");
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async downloadPdf(row) {
			const url = `/patients/${this.$route.params.id}/history/pdf`;
			try {
				const response = await api.post(url, {row}, {responseType: 'blob'});
				const urlPDF = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = urlPDF;
				link.setAttribute('download', 'Historial-Medico.pdf'); // Nombre del archivo para descarga
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				Toast.fire({
					icon: "success",
					title: "PDF generado"
				})
			} catch (e) {
				Toast.fire({
					icon: "error",
					title: "Error"
				})
			}
		},
	},
};
</script>
